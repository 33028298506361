import React from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import { Helmet } from 'react-helmet'

import FeatureCard from '../components/feature-card'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>HSE-PSM</title>
        <meta property="og:title" content="Grounded Fond Ant" />
      </Helmet>
      <div className="home-header">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <a href="#Home" className="home-link">
            <img
              alt="image"
              src="/hse-ps-high-resolution-logo-transparent-700w.png"
              loading="eager"
              className="home-image"
            />
          </a>
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links">
              <a href="#HeroSection" className="home-nav1">
                Home
              </a>
              <a href="#lottie3" className="home-nav2">
                Services
              </a>
              <a href="#Lottie2" className="home-nav3">
                About Us
              </a>
              <a href="#lottie5" className="home-nav5">
                Contact
              </a>
            </nav>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div className="home-nav">
              <div className="home-top">
                <span className="home-logo">HSEPS</span>
                <div data-thq="thq-close-menu" className="home-close-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links1">
                <span className="home-nav11">Home</span>
                <span className="home-nav21">Services</span>
                <span className="home-nav31">About Us</span>
                <span className="home-nav4">Clients</span>
                <span className="home-nav51">Contact</span>
              </nav>
              <div className="home-buttons">
                <button className="home-login button">Login</button>
                <button className="home-register button">Register</button>
              </div>
            </div>
            <div>
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <div className="home-hero">
        <div id="HeroSection" className="home-hero1">
          <div className="home-container1">
            <h1 className="home-hero-heading heading1">
              HSE &amp; Process Safety Consulting
            </h1>
            <span className="home-hero-sub-heading">
              Ensuring safety and compliance in every step
            </span>
            <div className="home-btn-group">
              <a
                href="#Lottie2"
                id="navigateToAbout"
                className="home-hero-button1 button"
              >
                Learn More -&gt;
              </a>
            </div>
          </div>
          <Player
            src={process.env.PUBLIC_URL + "windmill.json"}
            loop
            speed="1"
            autoplay
            background="transparent"
            className="home-lottie-node"
          ></Player>
        </div>
      </div>
      <div className="home-details">
        <div id="About Us Section" className="home-details1">
          <Player
            id="Lottie2"
            src={process.env.PUBLIC_URL + "bar graph.json"}
            loop
            speed="2"
            autoplay
            background="transparent"
            className="home-lottie-node1"
          ></Player>
          <div className="home-container2">
            <span id="AboutUs" className="home-text sectionTitle">
              <span>About US</span>
              <br></br>
            </span>
            <h2 id="zing" className="home-details-heading heading2">
              Why Choose Our Consulting Firm?
            </h2>
            <span className="home-details-sub-heading">
              SNEHA HSE &amp; PROCESS SAFETY Consultancy provides Technical HSE,
              Process Safety and Operating Integrity consultancy in India and in
              countries such as UAE, Qatar and Oman. We provide technical HSE
              consultancy services for Hazardous Chemical and upstream,
              midstream and downstream hydrocarbon industries. Our pool of
              experts are Technical professionals from ex-Shell, PDO Oman, ADNOC
              UAE and Indian Oil.
            </span>
          </div>
        </div>
      </div>
      <div className="home-features">
        <div className="home-features-container">
          <div id="Service Section" className="home-features1">
            <div className="home-container3"></div>
            <span id="lottie3" className="home-text03 sectionTitle">
              <span>SERVICES</span>
              <br></br>
            </span>
            <h2 id="lottie31" className="home-features-heading heading2">
              <span>Our Services</span>
              <br></br>
              <br></br>
            </h2>
            <div className="home-container4">
              <FeatureCard
                heading="Risk Assessment"
                subHeading="Process Hazard Analysis : HAZID, HAZOP, LOPA and BOWTIE "
              ></FeatureCard>
              <FeatureCard
                heading="HSE Critical Equipment &amp; System Management"
                subHeading="Identifying and managing critical points for optimal safety"
              ></FeatureCard>
              <FeatureCard
                heading="Work Management System"
                subHeading="Configurational setup keeping best practises in check.Streamlining procedures for safe and efficient operations."
              ></FeatureCard>
              <FeatureCard
                heading="Incident Prevention &amp; Mitigation"
                subHeading="Proactive strategies for preventing major accidents and maintaining operational integrity"
              ></FeatureCard>
              <FeatureCard
                heading="Process Safety Training &amp; Competency Development"
                subHeading="Equipping your team with the knowledge and skills to excel"
              ></FeatureCard>
              <FeatureCard
                heading="Customized Solutions"
                subHeading="Tailored studies, procedures, standards, and systems to meet your specific needs"
              ></FeatureCard>
              <FeatureCard
                heading="Audits &amp; Assessments"
                subHeading="Uncovering potential risks and optimizing your safety protocols"
              ></FeatureCard>
              <FeatureCard
                heading="Standards &amp; Procedures Development"
                subHeading="Establishing clear guidelines for ongoing safety and efficiency"
              ></FeatureCard>
              <FeatureCard
                heading="Advanced Fire Safety Studies"
                subHeading="Minimizing risk in the most challenging settings"
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-faq">
        <div className="home-faq-container">
          <div className="home-faq1">
            <div className="home-container5"></div>
            <Player
              id="lottie90"
              src={process.env.PUBLIC_URL + "tree.json"}
              loop
              speed="2"
              autoplay
              background="transparent"
              className="home-lottie-node2"
            ></Player>
          </div>
        </div>
      </div>
      <footer id="Footer Section" className="home-footer">
        <div id="contactus" className="home-container6">
          <img
            id="lottie5"
            alt="image"
            src="/hse-ps-high-resolution-logo-transparent-700w.png"
            className="home-image1"
          />
          <FeatureCard
            heading="Contact Us"
            subHeading="Devendra Upadhyay Contact:DEVEN3000@gmail.com WhatsApp+91-8980649725"
            rootClassName="feature-card-root-class-name"
          ></FeatureCard>
        </div>
      </footer>
      <span className="home-text09">© 2023 HSE-PS, All Rights Reserved.</span>
      <div className="home-footer1"></div>
    </div>
  )
}

export default Home;
